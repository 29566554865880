import { default as indexruLpQE5K2UMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/index.vue?macro=true";
import { default as packagesMJF6g1V0v1Meta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/packages.vue?macro=true";
import { default as partssDA6YYEPjMMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/parts.vue?macro=true";
import { default as customb8BsmdBENFMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom.vue?macro=true";
import { default as indexW6AwviUF8AMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/index.vue?macro=true";
import { default as ratesKUfpw3rWzWMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/rates.vue?macro=true";
import { default as subscriptionB21ceP7EdeMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/subscription.vue?macro=true";
import { default as accountKZxtDKMu33Meta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account.vue?macro=true";
import { default as _91id_93Lj4rAWpVPgMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as index2LnRZBkqM7Meta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93QNV1151ymZMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93MQyc9VhNqXMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexmRYxV6I5ZFMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesRH790JdvSaMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93KM5B6burLPMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexGYMdVz1e8fMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/index.vue?macro=true";
import { default as productsQnAZge86o8Meta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/products.vue?macro=true";
import { default as indexpiIqQ3cYYsMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/index.vue?macro=true";
import { default as configurationQYn6kZOfMyMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexBIyVqf614rMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as planbX8c0AVq2GMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksTe0gS5UdIdMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenanceRwX8xFXoVvMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualsG4DZBPJLvMMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/manuals.vue?macro=true";
import { default as times16v4CFoYKjMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/times.vue?macro=true";
import { default as catalogV45B0pcG4aMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog.vue?macro=true";
import { default as commande7Y46k3Su8JMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/commande.vue?macro=true";
import { default as _91tab_93SnYZt4kULMMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_93FyUwck2KDoMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93Rj133ZS7xXMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf4ehAgEOJlMMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexdP8tiT6hlLMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/index.vue?macro=true";
import { default as loginlUzapwWupWMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/login.vue?macro=true";
import { default as panierQWvReXkedkMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/panier.vue?macro=true";
import { default as indexpKXKVCZuwBMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique/index.vue?macro=true";
import { default as productsyWKK5A0DhiMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueUyLFmoWKGlMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique.vue?macro=true";
import { default as maintenanceQHGyEU16dHMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/print/maintenance.vue?macro=true";
import { default as productsNU1iopO9DwMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/products.vue?macro=true";
import { default as signupJEwY0r5EUwMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordJGAmabzBBNMeta } from "/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountKZxtDKMu33Meta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account.vue"),
    children: [
  {
    name: customb8BsmdBENFMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/boutique/products.vue")
  },
  {
    name: catalogV45B0pcG4aMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/index.vue")
  },
  {
    name: maintenanceRwX8xFXoVvMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf4ehAgEOJlMMeta || {},
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginlUzapwWupWMeta || {},
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/panier.vue")
  },
  {
    name: pneumatiqueUyLFmoWKGlMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceQHGyEU16dHMeta || {},
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupJEwY0r5EUwMeta || {},
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/essorauto.com/dev-app.essorauto.com/pages/tableau-de-bord.vue")
  }
]